import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.handleLinkToElement();
  }

  handleLinkToElement() {
    const linkedElementDomId = window.location.hash;
    if (!linkedElementDomId) return;

    setTimeout(
      () => {
        const hashElement = document.querySelector(linkedElementDomId);
        const navbar = document.querySelector("nav.navbar.header");
        /* eslint-disable */
        // window.pageYOffset is added to scroll correctly when page was scrolled, see comment:
        // https://stackoverflow.com/questions/73182860/getboundingclientrect-top-returning-wrong-value/74362001#74362001.
        /* eslint-enable */
        const scrollOffset = hashElement.getBoundingClientRect().top + window.scrollY -
          navbar.clientHeight;

        window.scrollTo({ top: scrollOffset });
      },
      100,
    );
  }
}
