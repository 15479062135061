import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["notePresenceIcon", "toggleButton", "noteSection"];

  connect() {
    this.initCollapse();
    this.initNotePresenceIcon();
  }

  disconnect() {
    $(this.noteSectionTarget).removeClass("show");
  }

  initCollapse() {
    const urlCompanyId = new URLSearchParams(window.location.search).get("placements_company_id");

    const $companyWithPlacementsBlock = $("#companies-with-placements");
    const $toggleButton = $(this.toggleButtonTarget);
    const $noteSection = $(this.noteSectionTarget);

    const currentCandidateId = $companyWithPlacementsBlock.data("notes-current-candidate-id");
    const toughbyteId = $companyWithPlacementsBlock.data("notes-toughbyte-id");

    if (urlCompanyId !== null) {
      window.notes_company_id = urlCompanyId;
    }

    if (typeof window.notes_current_candidate_id === "undefined") {
      window.notes_current_candidate_id = currentCandidateId;
    } else if (window.notes_current_candidate_id !== currentCandidateId) {
      window.notes_current_candidate_id = currentCandidateId;
      window.notes_company_id = undefined;
    }

    if (typeof window.notes_company_id === "undefined") {
      window.notes_company_id = toughbyteId;
    }

    const notesCompanyId = parseInt($toggleButton.data("company-id"), 10);
    const savedCompanyId = parseInt(window.notes_company_id, 10);

    $toggleButton.attr("data-bs-toggle", "collapse");
    $toggleButton.attr("data-bs-target", `.${$noteSection.attr("class").split(" ")[1]}`);
    $noteSection.attr("data-parent", "#companies-with-placements");
    $noteSection.addClass("collapse");

    if (notesCompanyId === savedCompanyId) {
      $toggleButton.attr("aria-expanded", "true");
      $noteSection.addClass("show");
    } else {
      $toggleButton.attr("aria-expanded", "false");
    }
  }

  updateSavedCompanyIdAndFocus() {
    const $toggleButton = $(this.toggleButtonTarget);
    const $noteSection = $(this.noteSectionTarget);

    if ($toggleButton.attr("aria-expanded") === "true") {
      window.notes_company_id = "0";
    } else {
      window.notes_company_id = $toggleButton.data("company-id");
      setTimeout(() => $noteSection.find(".new-note-area").trigger("focus"), 100);
    }
  }

  initNotePresenceIcon() {
    const $noteSection = $(this.noteSectionTarget);
    const noteCount = $noteSection.find(".note-thread .note").length;

    this.noteCount = noteCount;
    this.updateNotePresenceIcon();
  }

  updateNotePresenceIcon() {
    const $notePresenceIcon = $(this.notePresenceIconTarget);

    $notePresenceIcon.removeClass("notes-full-icon");
    $notePresenceIcon.removeClass("notes-empty-icon");

    if (this.noteCount > 0) {
      $notePresenceIcon.addClass("notes-full-icon");
    } else {
      $notePresenceIcon.addClass("notes-empty-icon");
    }
  }

  removeNoteCallback() {
    if (this.noteCount === 0) return;
    this.noteCount -= 1;
    this.updateNotePresenceIcon();
  }

  addNoteCallback() {
    this.noteCount += 1;
    this.updateNotePresenceIcon();
  }
}
