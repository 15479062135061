// Adapted some methods from https://github.com/airblade/stimulus-datepicker
export default class DateValidator {
  // @param str [String] plain user entered string
  static isValidStr(str) {
    if (!/^\d{2}\.\d{2}\.\d{4}$/.test(str)) return false;
    return this.isValidDate(...str.split(".").map((s) => +s));
  }

  // @param day [Number] day in month
  // @param month [Number] month number (January is 1)
  // @param year [Number] four-digit year
  static isValidDate(day, month, year) {
    if (year < 1000 || year > 9999) return false;
    if (month < 1 || month > 12) return false;
    if (day < 1 || day > this.daysInMonth(month, year)) return false;
    return true;
  }

  // Returns the number of days in the month.
  //
  // @param month [Number] the month (1 is January)
  // @param year [Number] the year (e.g. 2022)
  // @return [Number] the number of days
  static daysInMonth(month, year) {
    if ([1, 3, 5, 7, 8, 10, 12].includes(month)) return 31;
    if ([4, 6, 9, 11].includes(month)) return 30;
    return this.isLeapYear(year) ? 29 : 28;
  }

  static isLeapYear(year) {
    if ((year % 400) == 0) return true;
    if ((year % 100) == 0) return false;
    return year % 4 == 0;
  }
}
