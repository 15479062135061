import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const formUrl = new URL(this.element.action);
    formUrl.searchParams.append("time_zone", this.timezone);
    this.element.action = formUrl.toString();
  }

  get timezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
