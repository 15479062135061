import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["dateField"];

  dateFieldTargetConnected() {
    $(this.dateFieldTarget).datepicker({
      minDate: 0,
      dateFormat: "dd.mm.yy",
      firstDay: 1,
    });
  }
}
