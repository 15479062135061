import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (!window.location.hash) return;

    const element = document.querySelector(window.location.hash);

    if (!element) {
      window.history.pushState(null, null, " ");
      return;
    }
    window.location = window.location.hash;

    element.firstChild.click();
  }
}
