import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["label", "checkbox"];

  connect() {
    if ($(this.checkboxTarget).is(":checked")) $(this.labelTarget).addClass("fw-semibold");
  }

  toggleWeight() {
    if ($(this.labelTarget).hasClass("fw-semibold")) {
      $(this.labelTarget).removeClass("fw-semibold");
    } else {
      $(this.labelTarget).addClass("fw-semibold");
    }
  }
}
