import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["submit"];

  enableSubmit() {
    if (!this.submitTarget.classList.contains("disabled")) return;

    this.submitTarget.classList.remove("disabled");
    const tooltip = bootstrap.Tooltip.getOrCreateInstance(this.submitTarget.parentElement);
    tooltip.hide();
    tooltip.disable();
  }
}
