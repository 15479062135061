import $ from "jquery";
import { Tooltip } from "bootstrap";
import { removeTooltips } from "./tooltips";

$(document).on(
  "turbo:load",
  () => new Tooltip("body", { selector: '[data-bs-toggle="tooltip"]', trigger: "hover" }),
);

$(document).on("click", ".toggle-chevron-content", function toggleIcons() {
  $(this).find(".icon-chevron-hide, .icon-chevron-show").toggle();
});

[
  "turbo:click",
  "turbo:submit-start",
  "turbo:frame-render",
].forEach((eventName) => document.addEventListener(eventName, () => removeTooltips()));

$(document).on("submit", ".needs-validation", function validateForm(event) {
  if (this.checkValidity() === false) {
    event.preventDefault();
    event.stopImmediatePropagation();
    $(this).addClass("was-validated");
    return false;
  }

  return true;
});
