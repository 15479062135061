import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "formToSelect",
    "formBccInput",
    "formCcInput",
    "formSubject",
    "formBody",
    "newThreadForm",
    "newTemplateButton",
    "templateSelect",
  ];

  static outlets = [
    "button-utils",
  ];

  static values = {
    templateUrl: String,
  };

  newThreadFormTargetConnected() {
    this.#setupComposeInterruptHandler();
    this.initialSubjectContent = this.formSubjectTarget.value;
    this.buttonUtilsOutlets.forEach((btn) =>
      btn.disableWithTooltip(
        "Please make sure you send the previous email before opening a new one",
      )
    );
  }

  templateSelectTargetConnected() {
    this.templateSelectTarget.selectize.on("change", () => this.#applyTemplate());
  }

  formToSelectTargetConnected() {
    this.#findInputToFocus.focus();
  }

  closeForm(event) {
    if (
      !event.target.dataset.ignoreInterruptWarning &&
      this.#hasUnsavedChanges() &&
      !window.confirm(
        "You have unsaved changes. If you leave this page, you will lose those changes!",
      )
    ) return;

    this.newThreadFormTarget.remove();
    this.buttonUtilsOutlets.forEach((btn) => btn.enableAndDisposeTooltip());

    window.onbeforeunload = null;
  }

  submitNewTemplate() {
    const { href } = this.newTemplateButtonTarget;
    const url = new URL(href);

    const queryParams = {
      "email_template[subject]": this.formSubjectTarget.value,
      "email_template[body]": this.formBodyTarget.value,
      "commit": "Create Email template",
    };

    Object.keys(queryParams).forEach((key) => {
      url.searchParams.set(key, queryParams[key]);
    });

    this.newTemplateButtonTarget.href = url.toString();
    this.newTemplateButtonTarget.click();
  }

  toggleAddressField(event) {
    const parent = event.target.parentElement;
    event.target.remove();

    if (parent.children.length === 0) parent.remove();

    const { targetName } = event.target.dataset;
    const targetElement = this[`${targetName}Target`];

    if (targetElement) {
      targetElement.classList.remove("d-none");
      const inputElement = targetElement.querySelector("input");

      if (inputElement) {
        inputElement.focus();
      }
    }
  }

  disconnect() {
    document.removeEventListener("turbo:before-visit", this.#composeInterruptHandler);
    window.removeEventListener("beforeunload", this.#beforeunloadHandler);
  }

  #applyTemplate() {
    const value = this.templateSelectTarget.value;

    if (!value) return;

    const url = `${this.templateUrlValue}${value}?first_recipient=${this.formToSelectTarget.value}`;

    fetch(url)
      .then((response) => response.text())
      .then((json) => {
        const { subject, body } = JSON.parse(json);
        if (this.formSubjectTarget.value === "") {
          this.formSubjectTarget.value = subject;
        }
        this.formBodyTarget.editor.insertHTML(body);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  get #findInputToFocus() {
    if (this.formToSelectTarget.value === "") {
      return this.formToSelectTarget.selectize;
    }

    const subjectInput = this.formSubjectTarget;
    if (subjectInput.value === "") {
      return subjectInput;
    }
    return this.formBodyTarget;
  }

  #setupComposeInterruptHandler() {
    document.addEventListener("turbo:before-visit", this.#composeInterruptHandler);
    window.addEventListener("beforeunload", this.#beforeunloadHandler);
  }

  #beforeunloadHandler = (event) => {
    if (this.#hasUnsavedChanges()) {
      event.preventDefault();

      event.returnValue = "";
    }
  };

  #composeInterruptHandler = (event) => {
    if (
      this.#hasUnsavedChanges() &&
      !window.confirm(
        "You have unsaved changes. If you leave this page, you will lose those changes!",
      )
    ) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  #hasUnsavedChanges() {
    if (!this.hasFormSubjectTarget || !this.hasFormBodyTarget) return false;

    const subjectValue = this.formSubjectTarget.value;
    const bodyValue = this.formBodyTarget.value;
    return subjectValue !== this.initialSubjectContent ||
      (!!bodyValue && bodyValue !== "");
  }
}
