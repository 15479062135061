import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import * as bootstrap from "bootstrap";
import { requestSubmitPolyfilled } from "../src/shared/input_utils";

export default class extends Controller {
  static targets = [
    "confirmTabTrigger",
    "calendarTabTrigger",
    "confirmForm",
    "submitBtn",
    "timezonePicker",
    "subheaderCallTime",
    "hiddenFieldCallTime",
    "hiddenFieldTimeZone",
    "plainTimeZone",
    "utcOffset",
  ];

  connect() {
    const calendarTab = new bootstrap.Tab(this.calendarTabTriggerTarget);
    calendarTab.show();
    if (this.confirmFormTarget.checkValidity()) {
      const tooltip = bootstrap.Tooltip.getOrCreateInstance(this.submitBtnTarget.parentElement);
      tooltip.hide();
      tooltip.disable();
    }
  }

  timezonePickerTargetConnected(target) {
    const form = target.closest("form");

    this.disableEnterSubmit($(form));

    // Prevent doubling the event. It happens because for some reason the target is connected twice.
    target.selectize.off("change", () => {
      requestSubmitPolyfilled(form);
    });
    // Used explicitly `requestSubmitPolyfilled` because `turbo-instant-submit`
    // works incorrectly when we type something irrelevant in the select field and press enter.
    target.selectize.on("change", () => {
      requestSubmitPolyfilled(form);
    });
  }

  plainTimeZoneTargetConnected() {
    const plainTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Calculate the offset in seconds and swap the sign to match the Rails convention.
    const utcOffset = -new Date().getTimezoneOffset() * 60;

    this.plainTimeZoneTarget.value = plainTimezone;
    this.utcOffsetTarget.value = utcOffset;

    // Trigger change event to invoke turbo submit form.
    $(this.utcOffsetTarget).trigger("change");
  }

  showConfirm(event) {
    this.hiddenFieldTimeZoneTarget.value = this.timezonePickerTarget.value;
    this.formValidation();
    const confirmTab = new bootstrap.Tab(this.confirmTabTriggerTarget);
    window.history.pushState(
      null,
      "",
      `${window.location.pathname}/confirm${window.location.search}`,
    );
    $(window).on("popstate", () => window.location.reload());
    confirmTab.show();

    $(this.subheaderCallTimeTarget).text(event.params.callTimeFormatted);
    $(this.hiddenFieldCallTimeTarget).val(event.params.callTime);
  }

  backToCalendar() {
    const calendarTab = new bootstrap.Tab(this.calendarTabTriggerTarget);
    calendarTab.show();
    window.history.replaceState(
      window.history.state,
      "",
      window.location.href.replace("/confirm", ""),
    );
  }

  formValidation() {
    const $form = $(this.confirmFormTarget);
    const $btn = $(this.submitBtnTarget);
    const tooltip = bootstrap.Tooltip.getOrCreateInstance(this.submitBtnTarget.parentElement);
    this.disableEnterSubmit($form);

    $form.on("input.validation", () => {
      if (this.confirmFormTarget.checkValidity()) {
        $btn.removeClass("disabled");
        $btn.prop("disabled", false);
        tooltip.hide();
        tooltip.disable();
        return false;
      }

      $btn.addClass("disabled");
      $btn.prop("disabled", true);
      tooltip.enable();
      return true;
    });
  }

  disableEnterSubmit($element) {
    $element.on("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        return false;
      }

      return true;
    });
  }

  disconnect() {
    if (this.hasConfirmFormTarget) {
      $(this.confirmFormTarget).off("input.validation keydown");
    }
  }
}
