const CustomModal = () => {
  /**
   * Returns classes for footer button variants.
   * Variant names are the same as in ButtonComponent.
   * @param {string} variant - only the variants used in modal windows
   * @returns {Array<string>}
   */
  const variantBtnClasses = (variant) => {
    switch (variant) {
      case "primary":
        return ["btn-primary"];
      case "danger":
        return ["btn-danger"];
      case "cancel":
        return ["btn-light", "border"];
      default:
        return ["btn-primary"];
    }
  };

  /**
   * Returns an element for the header of the modal confirmation window with the passed text.
   * @param {string} text - header title text
   * @returns {HTMLDivElement}
   */
  const header = (text) => {
    const element = document.createElement("div");
    const title = document.createElement("h5");
    const closeButton = document.createElement("button");

    element.classList.add("modal-header");

    title.classList.add("modal-title");
    title.textContent = text;

    closeButton.classList.add("btn-close");
    closeButton.setAttribute("type", "button");
    closeButton.setAttribute("aria-label", "Close");
    closeButton.setAttribute("data-bs-dismiss", "modal");

    element.appendChild(title);
    element.appendChild(closeButton);

    return element;
  };

  /**
   * Returns an element for the body of the modal confirmation window with the passed content.
   * @param {string} content - body content
   * @returns {HTMLDivElement}
   */
  const body = (content) => {
    const element = document.createElement("div");
    const bodyContent = document.createElement("p");

    element.classList.add("modal-body");
    bodyContent.insertAdjacentHTML("afterbegin", content);
    element.appendChild(bodyContent);

    return element;
  };

  /**
   * Returns an element for the footer of the modal confirmation window with the buttons.
   * @param {object} params - the object with button params
   * @param {string} params.okLabel - the text that will be on the ok button
   * @param {string} params.okVariant - name of the ok button variant (as in the component)
   * @param {string} params.cancelLabel - the text that will be on the cancel button
   * @param {string} params.cancelVariant - name of the cancel button variant (as in the component)
   * @returns {HTMLDivElement}
   */
  const footer = (
    {
      okLabel = "Yes",
      okVariant = "primary",
      cancelLabel = "Cancel",
      cancelVariant = "cancel",
    },
  ) => {
    const element = document.createElement("div");
    const okBtn = document.createElement("button");
    const cancelBtn = document.createElement("button");
    const defaultBtnClasses = [
      "btn",
      "d-inline-flex",
      "gap-2",
      "align-items-center",
      "text-nowrap",
      "btn-small",
    ];

    element.classList.add("modal-footer");

    cancelBtn.classList.add(
      ...defaultBtnClasses,
      ...variantBtnClasses(cancelVariant),
    );
    cancelBtn.setAttribute("data-bs-dismiss", "modal");
    cancelBtn.setAttribute("type", "button");
    cancelBtn.textContent = cancelLabel;

    element.appendChild(cancelBtn);

    okBtn.classList.add(
      ...defaultBtnClasses,
      ...variantBtnClasses(okVariant),
    );
    okBtn.setAttribute("id", "confirm");
    okBtn.setAttribute("type", "button");
    okBtn.textContent = okLabel;

    element.appendChild(okBtn);

    return element;
  };

  /**
   * Wraps modal window content in blocks with classes needed for Bootstrap.
   * @param {Array<HTMLDivElement>} elementsToAppend - elements that should be in the modal window
   * @returns {HTMLDivElement}
   */
  const wrapper = (elementsToAppend) => {
    const modalElement = document.createElement("div");
    const wrapperElement = document.createElement("div");
    const innerWrapperElement = document.createElement("div");

    modalElement.classList.add("modal", "fade");
    wrapperElement.classList.add("modal-dialog");
    innerWrapperElement.classList.add("modal-content");

    modalElement.appendChild(wrapperElement);
    wrapperElement.appendChild(innerWrapperElement);
    innerWrapperElement.append(...elementsToAppend);

    return modalElement;
  };

  /**
   * Creates a modal window element.
   * @param {object} params - object with the parameters needed to create a modal
   * @param {string} params.headerText - modal window header title text
   * @param {object} params.data - object with dataset of the pressed element
   * @returns {HTMLDivElement}
   */
  const create = ({ headerText, data }) => {
    const headerElement = header(headerText);
    const footerElement = footer(data);
    const elementsToAppend = [];

    if (data.confirmBody) {
      const bodyElement = body(data.confirmBody);

      elementsToAppend.push(headerElement, bodyElement);
    } else {
      headerElement.classList.add("border-bottom-0");
      elementsToAppend.push(headerElement);
    }

    elementsToAppend.push(footerElement);

    return wrapper(elementsToAppend);
  };

  /**
   * Calls the creation of a modal confirmation window with the passed parameters
   * @param {object} params - object with the parameters needed to create a modal
   * @returns {HTMLDivElement} - modal element
   */
  const confirm = create;

  return { confirm };
};

export const customModal = CustomModal();
