import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["NameInputButton"];

  static values = { path: String, composedName: String };

  connect() {
    const path = this.pathValue;
    const composedName = this.composedNameValue;
    const targetInput = this.element.querySelector("input");

    this.NameInputButtonTarget.addEventListener("click", () => {
      if (path) {
        fetch(`${path}?name=${encodeURIComponent(targetInput.value)}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => response.json())
          .then((data) => {
            targetInput.value = data.formatted_name;
          });
      } else if (composedName) {
        targetInput.value = composedName;
      }
    });
  }
}
