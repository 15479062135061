import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import clamp from "../src/lib/clamp";

export default class extends Controller {
  static targets = ["text", "expandBtn", "collapseBtn"];

  static values = { lines: Number, inlineExpand: Boolean };

  connect() {
    const $expandBtn = $(this.expandBtnTarget);
    this.originalText = $(this.textTarget).html();

    this.options = { clamp: this.linesValue, useNativeClamp: false };
    if (this.inlineExpandValue) {
      this.options.truncationHTML = $expandBtn.prop("outerHTML");
      if ($expandBtn.find("svg").length > 0) {
        $expandBtn.hide();
        clamp(this.textTarget, this.options);
      } else {
        // If FontAwesome DOM watcher hasn't finished replacing i tag with svg,
        // attach mutation observer to button and wait until changes happen.
        this.expandBtnObserver = new MutationObserver(() => {
          this.expandBtnObserver.disconnect();
          this.options.truncationHTML = $(this.expandBtnTarget).prop("outerHTML");
          $expandBtn.hide();
          clamp(this.textTarget, this.options);
        });
        this.expandBtnObserver.observe(this.expandBtnTarget, { childList: true, subTree: true });
      }
    } else {
      clamp(this.textTarget, this.options);
      if ($(this.textTarget).html().length === this.originalText.length) {
        $expandBtn.hide();
      }
    }
  }

  expand() {
    if (this.inlineExpandValue) {
      $(this.textTarget).html(`${this.originalText}&nbsp;&nbsp;`);
      const $collapseButton = $(this.collapseBtnTarget).clone();
      $collapseButton.appendTo(this.textTarget);
      $collapseButton.show();
    } else {
      $(this.textTarget).html(this.originalText);
      $(this.expandBtnTarget).hide();
      $(this.collapseBtnTarget).show();
    }
  }

  collapse() {
    $(this.textTarget).html(this.originalText);
    clamp(this.textTarget, this.options);
    $(this.collapseBtnTarget).hide();
    if (!this.inlineExpandValue) {
      $(this.expandBtnTarget).show();
    }
  }
}
