import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["availableFrom", "availability", "hourlyRate", "lookingFor", "monthlySalary"];

  lookingForTargetConnected(target) {
    target.selectize.on("change", (newValue) => {
      if (newValue === "employment") {
        this.monthlySalaryTarget.classList.remove("hidden");
        this.hourlyRateTarget.classList.add("hidden");
      } else if (newValue === "contracting") {
        this.hourlyRateTarget.classList.remove("hidden");
        this.monthlySalaryTarget.classList.add("hidden");
      } else if (newValue === "") {
        this.hourlyRateTarget.classList.add("hidden");
        this.monthlySalaryTarget.classList.add("hidden");
      } else {
        this.hourlyRateTarget.classList.remove("hidden");
        this.monthlySalaryTarget.classList.remove("hidden");
      }

      if (newValue.includes("contracting")) {
        this.availableFromTarget.classList.remove("hidden");
        this.availabilityTarget.classList.remove("hidden");
      } else {
        this.availableFromTarget.classList.add("hidden");
        this.availabilityTarget.classList.add("hidden");
      }
    });
  }
}
