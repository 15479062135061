import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import DateValidator from "../src/lib/date_validator";

export default class extends Controller {
  static values = {
    maxDate: String,
    minDate: String,
    yearRange: { type: String, default: "-10:+10" },
    changeMonth: Boolean,
    changeYear: Boolean,
  };

  connect() {
    $(this.element).datepicker({
      dateFormat: "dd.mm.yy",
      firstDay: 1,
      changeMonth: this.changeMonthValue,
      changeYear: this.changeYearValue,
      minDate: this.minDateValue,
      maxDate: this.maxDateValue,
      yearRange: this.yearRangeValue,
    });

    // Text values cannot be entered in the date field, but can be pasted,
    // which can cause a problem with date parsing.
    this.element.addEventListener("paste", (event) => event.preventDefault());
  }

  validate({ target }) {
    const dateStr = target.value;
    const message = !dateStr
      ? ""
      : DateValidator.isValidStr(dateStr)
      ? ""
      : "Please enter a valid date in “DD.MM.YYYY” format";

    target.setCustomValidity(message);
    if (message) target.reportValidity();
  }
}
