import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = [
    "iconReaction",
  ];

  hideTooltip() {
    $(this.iconReactionTarget).find("[data-bs-toggle=tooltip]").tooltip("hide");
  }
}
