import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["password", "confirmation"];

  validate(event) {
    if (!this.checkValidity()) {
      event.preventDefault();
      event.stopImmediatePropagation();
      return false;
    }

    return true;
  }

  checkValidity() {
    const passwordValue = this.passwordTarget.value;
    const confirmationValue = this.confirmationTarget.value;

    if (passwordValue.length < 6) {
      this.passwordTarget.nextElementSibling.textContent =
        "Password needs to be at least 6 characters long.";
      this.setInvalid(this.passwordTarget);
      return false;
    }
    if (passwordValue !== confirmationValue) {
      this.confirmationTarget.nextElementSibling.textContent =
        "Password and confirmation don't match.";
      this.setInvalid(this.confirmationTarget);
      this.setValid(this.passwordTarget);
      return false;
    }

    return true;
  }

  setInvalid(input) {
    input.classList.add("is-invalid");
    input.classList.remove("is-valid");
  }

  setValid(input) {
    input.classList.add("is-valid");
    input.classList.remove("is-invalid");
  }
}
