import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editButtonOrEmptySection"];

  hideEditButtonsAndEmptySections() {
    this.editButtonOrEmptySectionTargets.forEach((element) => element.classList.add("d-none"));
  }

  showEditButtonsAndEmptySections(event) {
    if (!event.detail.success && event.type === "turbo:submit-end") return;

    this.editButtonOrEmptySectionTargets.forEach((element) => element.classList.remove("d-none"));
  }
}
