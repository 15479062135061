import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["positionInput", "companySelect"];

  changeFieldsRequiredStatus() {
    if (this.positionInputTarget.value === "") {
      this.companySelectTarget.removeAttribute("required");
    } else {
      this.companySelectTarget.setAttribute("required", "true");
    }
  }
}
