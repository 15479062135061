import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["questionInput", "suggestionList"];

  static values = {
    url: String,
    type: String,
  };

  connect() {
    $(this.suggestionListTarget).on("click", "li", (clickEvent) => {
      $(this.questionInputTarget).val($(clickEvent.target).text());
      $(this.suggestionListTarget).empty();
    });

    $(this.questionInputTarget).on("focusin", () => {
      $(this.suggestionListTarget).removeClass("d-none");
    });

    $(this.questionInputTarget).on("focusout", (event) => {
      if (!(event.relatedTarget === this.suggestionListTarget)) {
        $(this.suggestionListTarget).addClass("d-none");
      }
    });

    $(this.questionInputTarget).on("input", (event) => {
      const value = $(event.target).val();

      if (value.length === 0 || value.length < 3) {
        $(this.suggestionListTarget).empty();
      } else {
        get(
          this.urlValue,
          {
            query: { type: this.typeValue, question: value },
          },
        );
      }
    });
  }
}
