import "../src/shared/global_jquery_loader";

import "@hotwired/turbo-rails";

import "bootstrap";

import "../../stylesheets/entrypoints/public.scss";

import "../src/public";
import "../controllers";

import { config, dom, library } from "@fortawesome/fontawesome-svg-core";
// light icons
import {
  faAddressCard as faAddressCardLight,
  faAt as faAtLight,
  faBriefcase as faBriefcaseLight,
  faBuilding as faBuildingLight,
  faChalkboardUser as faChalkboardUserLight,
  faCheckCircle as faCheckCircleLight,
  faCircleInfo as faCircleInfoLight,
  faCircleNodes as faCircleNodesLight,
  faEnvelopeOpenText as faEnvelopeOpenTextLight,
  faExternalLink as faExternalLinkLight,
  faEye as faEyeLight,
  faEyeSlash as faEyeSlashLight,
  faFileAlt as faFileAltLight,
  faFileLines as faFileLinesLight,
  faGears as faGearsLight,
  faHistory as faHistoryLight,
  faLink as faLinkLight,
  faLoader as faLoaderLight,
  faLongArrowRight,
  faMessageLines as faMessageLinesLight,
  faNotebook as faNotebookLight,
  faPencilAlt as faPencilAltLight,
  faPeopleArrows as faPeopleArrowsLight,
  faPhone as faPhoneLight,
  faPlaneDeparture as faPlaneDepartureLight,
  faPlus as faPlusLight,
  faReply as faReplyLight,
  faRocketLaunch as faRocketLaunchLight,
  faShuffle as faShuffleLight,
  faStarSharp as faStarSharpOutlineLight,
  faThumbsUp as faThumbsUpLight,
  faTrashAlt as faTrashAltLight,
  faUsers as faUsersLight,
} from "@fortawesome/pro-light-svg-icons";
// solid icons
import {
  faArrowDown as faArrowDownSolid,
  faArrowLeft as faArrowLeftSolid,
  faArrowRight as faArrowRightSolid,
  faArrowUp as faArrowUpSolid,
  faBan as faBanSolid,
  faBriefcase as faBriefcaseSolid,
  faBuilding as faBuildingSolid,
  faCircle as faCircleSolid,
  faClock as faClockSolid,
  faCode as faCodeSolid,
  faEnvelopeSquare as faEnvelopeSquareSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faExternalLink as faExternalLinkSolid,
  faFileUser as faFileUserSolid,
  faGlobe as faGlobeSolid,
  faGraduationCap as faGraduationCapSolid,
  faHeartbeat as faHeartbeatSolid,
  faLink as faLinkSolid,
  faLocationArrow as faLocationArrowSolid,
  faPencilAlt as faPencilAltSolid,
  faPhoneSquareAlt as faPhoneSquareAltSolid,
  faQuoteLeft,
  faQuoteRight,
  faRectangleTerminal as faRectangleTerminalSolid,
  faRedo as faRedoSolid,
  faStarSharp as faStarSharpSolid,
  faStarSharpHalfAlt as faStarSharpHalfSolid,
  faThumbsUp as faThumbsUpSolid,
  faTimes as faTimesSolid,
  faTrashAlt as faTrashAltSolid,
  faUpload as faUploadSolid,
  faUser as faUserSolid,
  faUserFriends as faUserFriendsSolid,
  faUserGroup as faUserGroupSolid,
} from "@fortawesome/pro-solid-svg-icons";
// brands icons
import {
  faApple,
  faFacebookSquare,
  faGithub,
  faGooglePlay,
  faInstagramSquare,
  faLinkedin,
  faSkype,
  faSquareXTwitter,
  faTelegram,
  faVk,
} from "@fortawesome/free-brands-svg-icons";
// regular icons
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faCloudUpload,
  faCopy,
  faEllipsisH,
  faFileUpload,
  faList,
  faStarSharp as faStarSharpOutline,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  faAddressCardLight,
  faApple,
  faArrowDownSolid,
  faArrowLeftSolid,
  faArrowRightSolid,
  faArrowUpSolid,
  faAtLight,
  faBanSolid,
  faBriefcaseLight,
  faBriefcaseSolid,
  faBuildingLight,
  faBuildingSolid,
  faChalkboardUserLight,
  faCheckCircleLight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleSolid,
  faCircleInfoLight,
  faCircleNodesLight,
  faClock,
  faClockSolid,
  faCloudUpload,
  faCodeSolid,
  faCopy,
  faEllipsisH,
  faEnvelopeOpenTextLight,
  faEnvelopeSquareSolid,
  faExclamationCircleSolid,
  faExternalLinkLight,
  faExternalLinkSolid,
  faEyeLight,
  faEyeSlashLight,
  faFacebookSquare,
  faFileAltLight,
  faFileLinesLight,
  faFileUpload,
  faFileUserSolid,
  faGearsLight,
  faGithub,
  faGlobeSolid,
  faGooglePlay,
  faGraduationCapSolid,
  faHeartbeatSolid,
  faHistoryLight,
  faInstagramSquare,
  faLinkLight,
  faLinkSolid,
  faLinkedin,
  faList,
  faLoaderLight,
  faLocationArrowSolid,
  faLongArrowRight,
  faMessageLinesLight,
  faNotebookLight,
  faPencilAltLight,
  faPencilAltSolid,
  faPeopleArrowsLight,
  faPhoneLight,
  faPhoneSquareAltSolid,
  faPlaneDepartureLight,
  faPlusLight,
  faQuoteLeft,
  faQuoteRight,
  faRectangleTerminalSolid,
  faRedoSolid,
  faReplyLight,
  faRocketLaunchLight,
  faShuffleLight,
  faSkype,
  faStarSharpSolid,
  faStarSharpHalfSolid,
  faStarSharpOutline,
  faStarSharpOutlineLight,
  faTelegram,
  faThumbsUpLight,
  faThumbsUpSolid,
  faTimesSolid,
  faTrashAltLight,
  faTrashAltSolid,
  faSquareXTwitter,
  faUploadSolid,
  faUser,
  faUserFriendsSolid,
  faUserGroupSolid,
  faUserSolid,
  faUsersLight,
  faVk,
);
config.mutateApproach = "sync";
dom.watch();
