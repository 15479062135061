import ysFixWebmDuration from "fix-webm-duration";
import * as Sentry from "@sentry/browser";

export default class Recorder {
  constructor({ onRecordStart, onRecordStop, onNotice = alert }) {
    try {
      if (
        window.MediaRecorder === undefined ||
        !navigator.mediaDevices.getDisplayMedia
      ) {
        onNotice("Your browser is not supported.");
        return;
      }
    } catch (e) {
      onNotice(`Could not start recording: ${e.message.toLowerCase()}.`);
      return;
    }

    const composedStream = new MediaStream();
    this.init(onNotice).then(() => {
      if (this.screenStream.getAudioTracks().length > 0) {
        const context = new AudioContext();
        const audioDestination = context.createMediaStreamDestination();
        context.createMediaStreamSource(this.screenStream).connect(audioDestination);

        if (this.micStream && this.micStream.getAudioTracks().length > 0) {
          context.createMediaStreamSource(this.micStream).connect(audioDestination);
        } else {
          onNotice("Please connect a microphone.");
          return false;
        }

        audioDestination.stream.getAudioTracks().forEach((audioTrack) => {
          composedStream.addTrack(audioTrack);
        });

        this.recorder = this.createRecorder(composedStream, onRecordStart, onRecordStop);
        this.screenStream.getVideoTracks()[0].addEventListener("ended", this.stop);
        this.streams = [composedStream, this.screenStream, this.micStream];

        this.start = Date.now();
        return true;
      }

      onNotice(
        'Please check "Share audio" in the "Choose what to share" dialog. ' +
          "If you can't see it, select the tab you want to record first.",
      );
      return false;
    }).catch((e) => {
      onNotice(`Could not start recording: ${e.message.toLowerCase()}.`);
      return false;
    });
  }

  init = async (onNotice) => {
    if (
      (await navigator.mediaDevices.enumerateDevices()).filter(
        (device) => device.kind === "audioinput",
      ).length === 0
    ) {
      onNotice("No audio devices found.");
      return false;
    }

    this.screenStream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: true,
    });

    this.micStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    return Promise.resolve();
  };

  createRecorder = (stream, onRecordStart, onRecordStop) => {
    const recorder = new MediaRecorder(stream, {
      mimeType: "audio/webm",
      audioBitsPerSecond: 12000,
    });
    const chunks = [];
    recorder.ondataavailable = (e) => chunks.push(e.data);
    recorder.onstop = async () => {
      const blob = new Blob(chunks, { type: "audio/webm" });
      const duration = Date.now() - this.start;
      const name = `${new Date().toISOString().replace(/[^0-9]/g, "").substr(0, 14)}.webm`;
      let resultBlob = blob;
      try {
        resultBlob = await this.fixDuration(blob, duration);
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        onRecordStop(resultBlob, name, duration, this.start);
      }
    };
    recorder.start();
    onRecordStart(this);
    return recorder;
  };

  stop = () => {
    if (this.recorder.state === "inactive") return;

    this.recorder.stop();
    this.streams.forEach((stream) => {
      stream.getTracks().forEach((track) => track.stop());
    });
  };

  interrupt = () => {
    this.recorder.onstop = async () => {};
    this.stop();
  };

  fixDuration = (blob, duration) =>
    new Promise((resolve) => {
      ysFixWebmDuration(blob, duration, (result) => resolve(result));
    });
}
