import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

// In Turbo all the buttons are disabled automatically, from docs:
//
// During a submission, Turbo Drive will set the “submitter” element’s disabled attribute
// when the submission begins, then remove the attribute after the submission ends.
// https://turbo.hotwired.dev/handbook/drive#form-submissions
//
// This controller MUST only be used in cases where we specifically disable turbo with
// data-turbo=false. In all other cases we should rely on the native Turbo behavior.
export default class extends Controller {
  connect() {
    $(this.element).on("submit", () => {
      $(this.element).find('[type="submit"]').attr("disabled", true);
    });
  }
}
