import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import bootbox from "bootbox";
import { Turbo } from "@hotwired/turbo-rails";
import { post } from "@rails/request.js";
import Recorder from "../src/lib/recorder";
import { requestSubmitPolyfilled } from "../src/shared/input_utils";

export default class extends Controller {
  static targets = [
    "dateField",
    "timeField",
    "uploadButton",
    "uploadField",
    "durationField",
    "submitButton",
    "startRecordingButton",
    "stopRecordingButton",
  ];

  static values = {
    callToId: Number,
    callToType: String,
    recordUrl: String,
    redirectUrl: String,
    model: String,
  };

  connect() {
    this.disableEnterSubmit();
  }

  startRecording() {
    this.recorder = new Recorder({
      onRecordStart: this.handleRecordStart,
      onRecordStop: this.handleRecordStop,
      onNotice: this.handleNotice,
    });
  }

  handleRecordStart = (recorder) => {
    $(this.startRecordingButtonTarget).addClass("d-none");
    $(this.stopRecordingButtonTarget).removeClass("d-none");
    this.preventRecordInterruption();

    $(this.stopRecordingButtonTarget).off("click");
    $(this.stopRecordingButtonTarget).on("click", () => recorder.stop());
  };

  handleRecordStop = (blob, name, duration, startedAt) => {
    const $startButton = $(this.startRecordingButtonTarget);
    const $stopButton = $(this.stopRecordingButtonTarget);

    $stopButton.addClass("d-none");
    $startButton.removeClass("d-none");
    $stopButton.off("click");

    $startButton.prop("disabled", true);
    $startButton.addClass("disabled");
    $startButton.html("Uploading");

    this.removeRecordInterruptionListeners();
    this.preventUploadInterruption();

    this.createAlert(
      `Started uploading, if this process takes too long ${
        this.blobDownloadLink(blob, name, "click here")
      } to download the recording.`,
      "notification alert-warning",
    );

    post(
      this.recordUrlValue,
      {
        headers: { "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content") },
        body: this.createFormData(blob, name, duration, startedAt),
        redirect: "follow",
        responseKind: "turbo-stream",
      },
    ).then((response) => {
      const errors = [
        `An error occurred while uploading the audio recording, ${
          this.blobDownloadLink(blob, name, "click here")
        } to download it.`,
      ];
      this.removeUploadInterruptionListeners();

      if (response.redirected) {
        Turbo.visit(response.response.url);
        throw new Error("Redirected");
      } else if (response.statusCode === 422) {
        response.renderTurboStream();
      } else {
        errors.push("Unexpected response");
      }

      $startButton.prop("disabled", false);
      $startButton.html("Record");
      errors.forEach((error) => this.createAlert(error, "notification alert-danger py-2"));
    }).catch((error) => {
      if (error.message !== "Redirected") console.error(error);
    });
  };

  handleNotice(message) {
    bootbox.alert({
      message,
      callback: () => {},
    });
  }

  enableUploadUnlessEmpty() {
    if (this.dateFieldTarget.value !== "" && this.timeFieldTarget.value !== "") {
      this.uploadFieldTarget.removeAttribute("disabled");
      this.uploadButtonTarget.removeAttribute("disabled");
      this.uploadButtonTarget.classList.remove("disabled");
    } else {
      this.uploadFieldTarget.setAttribute("disabled", true);
      this.uploadButtonTarget.setAttribute("disabled", true);
      this.uploadButtonTarget.classList.add("disabled");
    }
  }

  extractDurationAndSubmit() {
    if (
      window.performance &&
      window.performance.getEntriesByType("navigation")[0].type === "back_forward"
    ) {
      // If "Back" button was pressed, the form should not be resubmitted because it can
      // cause resubmission of uploaded files.
      return;
    }
    this.extractDurationFromInput()
      .then((time) => {
        $(this.durationFieldTarget).val(time);
        requestSubmitPolyfilled(this.uploadFieldTarget.form);
      })
      .catch((error) => {
        $("#upload-call-modal").modal("hide");
        this.createAlert(
          `An error occured when uploading file: ${error.message}.`,
          "notification alert-danger",
        );
      });
  }

  disableEnterSubmit() {
    if (this.hasDateFieldTarget) {
      $(window).on("keydown", (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          return false;
        }

        return true;
      });
    }
  }

  removeListenersAndRedirectBack() {
    this.removeInterruptionListeners("uploadInterrupt");
    Turbo.visit(this.redirectUrlValue);
  }

  createFormData(blob, name, duration, startedAt) {
    const form = new FormData();

    form.append("file", blob, name);
    form.append("started_at", startedAt);
    form.append("duration", duration);
    form.append("call_to_id", this.callToIdValue);
    form.append("call_to_type", this.callToTypeValue);
    form.append("model", this.modelValue);

    return form;
  }

  createAlert(content, htmlClass) {
    let $alertField = $("#alerts2");
    if ($alertField.length === 0) {
      $alertField = $("#alerts").clone().attr("id", "alerts2").empty();
      $("#alerts").after($alertField);
    }
    const element = document.createElement("p");
    element.setAttribute("class", htmlClass);
    element.setAttribute("role", "alert");
    element.innerHTML = content;

    $alertField.append(element);
  }

  preventRecordInterruption() {
    this.preventWithMessage(
      "recordInterrupt",
      "Call recording is still in progress and will be lost." +
        "Are you sure you want to close the page?",
    );
  }

  preventUploadInterruption() {
    this.preventWithMessage(
      "uploadInterrupt",
      "Call recording is still being uploaded. Are you sure you want to close the page?",
    );
  }

  removeRecordInterruptionListeners() {
    this.removeInterruptionListeners("recordInterrupt");
  }

  removeUploadInterruptionListeners() {
    this.removeInterruptionListeners("uploadInterrupt");
  }

  removeInterruptionListeners(eventNamespace) {
    $(document).off(`turbo:before-visit.${eventNamespace}`);
    $(window).off(`beforeunload.${eventNamespace}`);
  }

  preventWithMessage(eventNamespace, message) {
    $(document).on(`turbo:before-visit.${eventNamespace}`, (event) => {
      if (!window.confirm(message)) {
        event.preventDefault();
        return false;
      }
      this.removeInterruptionListeners(eventNamespace);
      this.recorder.interrupt();
      return true;
    });
    $(window).on(`beforeunload.${eventNamespace}`, () => message);
  }

  extractDurationFromInput() {
    return new Promise((resolve, reject) => {
      const file = $(this.uploadFieldTarget).prop("files")[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (file.type === "video/webm" || file.type === "audio/wav") {
            this.extractAudioDuration(event, resolve);
          } else {
            resolve();
          }
        };
        this.preventUploadInterruption();
        reader.readAsDataURL(file);
      } else {
        reject(new Error("no file selected"));
      }
    });
  }

  extractAudioDuration(event, callback) {
    let timedOut = false;
    const audioElement = document.createElement("audio");
    audioElement.src = event.target.result;
    const timeout = setTimeout(() => {
      timedOut = true;
      callback();
    }, 10000);
    const timer = setInterval(() => {
      if (timedOut) {
        clearInterval(timer);
      } else if (audioElement.readyState === 4) {
        const time = audioElement.duration;
        callback(time);
        clearInterval(timer);
        clearTimeout(timeout);
      }
    }, 500);
  }

  blobDownloadLink(blob, name, text) {
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute("download", name);
    downloadLink.setAttribute("name", name);
    downloadLink.setAttribute("id", "recorder-link");
    downloadLink.innerHTML = text;
    return downloadLink.outerHTML;
  }
}
