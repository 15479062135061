import $ from "jquery";
import LazyLoad from "vanilla-lazyload";
import "../shared/profile_button";
import "../shared/bootstrap_activators";
import "../shared/modal_remover";
import initTurboConfirmations from "../shared/turbo_confirmations";
import { activateInstanceSubmit, activateKeybindShortcuts } from "../shared/input_utils";

initTurboConfirmations();
activateInstanceSubmit();
activateKeybindShortcuts();

function hideCookieNotice() {
  const cookieNotice = document.getElementById("cookie-notice");
  if (cookieNotice) {
    cookieNotice.style.display = "none";
  }
}

$(document).on("hidden.bs.dropdown", "#company-representative-disqualify-dropdown", (event) => {
  const $option = $(event.clickEvent.target);
  if ($option.hasClass("dropdown-item")) {
    const reason = $option.data("value");
    const reasonText = $option.text();
    $('#company-representative-disqualify-dropdown [name="disqualify_reason"]').val(reason);
    $("#disqualify-reason-button").text(reasonText);
    $('.modal-dialog [type="submit"]').removeAttr("disabled");
    $('#company-representative-disqualify-dropdown [data-bs-toggle="tooltip"]').tooltip("hide");
  }
});

$(window).on("hashchange", () => {
  if (window.location.hash === "#email") {
    $("#email").trigger("focus");
  }
});

function init() {
  const acceptButton = document.getElementById("accept-cookie-notice");
  if (acceptButton) {
    acceptButton.addEventListener("click", () => {
      document.cookie = "cookie-notice-accepted=1; max-age=31536000;";
      hideCookieNotice();

      // execute GTM script
      /* eslint-disable */
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", gon.gtm_id);
      /* eslint-enable */
    });
  }

  const filters = document.getElementById("filters");
  if (filters) {
    if (filters.dataset.scrollTo === "true") filters.scrollIntoView();
  }

  window.lazyLoad.update();

  $(".instant-submit").on("change", function submitForm() {
    if (
      window.performance &&
      window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD
    ) {
      // If "Back" button was pressed, the form should not be resubmitted because it can
      // cause resubmission of uploaded files.
      return;
    }
    $(this).closest("form").submit();
  });

  $(".notification-close").click(function closeNotification() {
    // Class d-flex is removed because it has display: flex !important; style
    // that overrides display: none from fadeOut;
    $(this).closest(".notification").removeClass("d-flex");
    $(this).closest(".notification").fadeOut(300);
  });
}

window.lazyLoad = new LazyLoad({ threshold: 100 });

document.addEventListener("turbo:load", init);
