import { Controller } from "@hotwired/stimulus";
import { handleModalResponse } from "../src/shared/turbo_confirmations";
import { requestSubmitPolyfilled } from "../src/shared/input_utils";

// This controller must be connected directly to the button.
export default class extends Controller {
  static targets = ["text"];

  textTargetConnected(target) {
    target.setAttribute("original-text", target.textContent);
  }

  async updateTextAndShowConfirmIfNeeded({ target }) {
    this.#setTextTargetContent(target);

    if (target.dataset.turboConfirm) {
      this.#disableButton(this.element);

      const modalResponse = await handleModalResponse(
        target.dataset.turboConfirm,
        target.form,
        target,
      );

      if (!modalResponse) {
        target.value = "";
        this.#setTextTargetContent(target);
        this.#enableButton(this.element);
        return;
      }
    }
    target.dispatchEvent(
      new CustomEvent("fileuploaded", {
        bubbles: true,
      }),
    );
  }

  submitForm({ target }) {
    requestSubmitPolyfilled(target.form);
  }

  #setTextTargetContent(fileInput) {
    this.textTarget.textContent = fileInput.files[0]
      ? "Uploaded"
      : this.textTarget.getAttribute("original-text");
  }

  #disableButton(button) {
    button.classList.add("disabled");
    button.setAttribute("disabled", true);
  }

  #enableButton(button) {
    button.classList.remove("disabled");
    button.removeAttribute("disabled");
  }
}
