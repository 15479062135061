import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["enField", "ruField", "languageSelect", "editCloneButton"];

  connect() {
    $(this.element).on("click", ".trix-button--icon-link", (e) => {
      const $linkButton = $(e.target);
      $linkButton.closest("trix-toolbar").find("input[name=href]").prop("type", "text");
    });
  }

  languageSelectTargetConnected(target) {
    this.#applyLanguageSelect(target.value);

    if (this.hasEditCloneButtonTarget) {
      this.#applyLanguageToButtonHrefParams(target.value);
    }

    target.selectize.on("change", (value) => this.#toggleLanguage(value));
  }

  #toggleLanguage(value) {
    this.#applyLanguageSelect(value);
    this.#applyLanguageToButtonHrefParams(value);
  }

  #applyLanguageToButtonHrefParams(language) {
    this.editCloneButtonTargets.forEach((btn) => {
      const url = new URL(btn.href);
      url.searchParams.set("lang", language);
      btn.setAttribute("href", url);
    });
  }

  #applyLanguageSelect(language) {
    if (language === "russian") {
      this.ruFieldTargets.forEach((field) => field.classList.remove("hidden"));
      this.enFieldTargets.forEach((field) => field.classList.add("hidden"));
    }
    if (language === "english") {
      this.enFieldTargets.forEach((field) => field.classList.remove("hidden"));
      this.ruFieldTargets.forEach((field) => field.classList.add("hidden"));
    }
  }
}
