import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name", "email", "select"];

  selectTargetConnected() {
    this.selectTarget.selectize.on("change", () => {
      this.handleChange();
    });
  }

  selectTargetDisconnected() {
    this.selectTarget.selectize.off("change", () => {
      this.handleChange();
    });
  }

  handleChange() {
    const select = this.selectTarget;
    const email = select.value;
    const name = select.selectize.options[select.value].label;

    const emailInput = this.emailTarget;
    const nameInput = this.nameTarget;
    emailInput.value = email;
    nameInput.value = name;

    if (emailInput.value === "") emailInput.placeholder = "Email";
  }
}
