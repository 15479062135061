import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["status", "anonymous"];

  statusTargetConnected(target) {
    target.selectize.on("change", (newStatus) => {
      this.anonymousTarget.classList.toggle(
        "hidden",
        newStatus !== "closed" && newStatus !== "signed",
      );
    });
  }
}
