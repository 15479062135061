import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const queryString = window.location.search;

    if (queryString.includes("_carousel")) {
      window.history.replaceState(
        window.history.state,
        null,
        window.location.pathname,
      );
    }
  }
}
