import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "audioPlayer",
  ];

  setTimecode({ params }) {
    this.audioPlayerTarget.currentTime = params.timecode;
    this.audioPlayerTarget.play();
  }
}
