import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["description"];

  connect() {
    if (this.hasDescriptionTarget) {
      $(this.descriptionTarget).find("button.trix-button--icon-link").remove();
      const { editor } = $(this.descriptionTarget).find("trix-editor")[0];
      editor.element.editorController.toolbarController.updateActions({ link: false });
    }
  }
}
