import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "candidatesFrom",
    "searchFrom",
    "workArrangements",
    "relocation",
    "visaSponsorship",
    "visaSponsorshipInput",
  ];

  candidatesFromTargetConnected() {
    const autofillFrom = this.candidatesFromTarget;
    const autofillTo = this.searchFromTarget;

    autofillFrom.selectize.on("option_add", (_val, data) => {
      autofillTo.selectize.addOption(data, true);
    });

    autofillFrom.selectize.on("item_remove", (val) => {
      autofillTo.selectize.removeItem(val);
    });

    autofillFrom.selectize.on("item_add", (val) => {
      autofillTo.selectize.addItem(val, true);
    });

    this.workArrangementsTarget.selectize.on("change", (newValue) => {
      if (String(newValue) === "remote") {
        this.visaSponsorshipTarget.classList.add("hidden");
        this.visaSponsorshipInputTarget.selectize.isRequired = false;
        this.visaSponsorshipInputTarget.selectize.$control_input.prop("required", false);
        this.relocationTarget.classList.add("hidden");
        this.relocationTarget.querySelector("input").removeAttribute("required");
      } else {
        if (
          this.visaSponsorshipTarget.querySelectorAll(".item").length === 0 &&
          String(newValue) !== ""
        ) {
          this.visaSponsorshipInputTarget.selectize.isRequired = true;
          this.visaSponsorshipInputTarget.selectize.$control_input.prop("required", true);
        } else if (String(newValue) === "") {
          this.visaSponsorshipInputTarget.selectize.isRequired = false;
          this.visaSponsorshipInputTarget.selectize.$control_input.prop("required", false);
        }
        this.visaSponsorshipTarget.classList.remove("hidden");

        if (this.relocationTarget.querySelectorAll(".item").length === 0) {
          this.relocationTarget.querySelector("input").setAttribute("required", "true");
        }
        this.relocationTarget.classList.remove("hidden");
      }

      if (
        this.workArrangementsTarget.selectize.getValue().includes("hybrid") &&
        !this.workArrangementsTarget.selectize.getValue().includes("onsite")
      ) {
        this.workArrangementsTarget.selectize.addItem("onsite");
      }
    });
  }
}
