import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["level", "minExperience", "maxExperience"];

  levelTargetConnected() {
    const selectizeInstance = this.levelTarget.selectize;
    if (!selectizeInstance) return;

    selectizeInstance.on("change", this.handleChange);
  }

  levelTargetDisconnected() {
    const selectizeInstance = this.levelTarget.selectize;
    if (!selectizeInstance) return;

    selectizeInstance.off("change", this.handleChange);
  }

  handleChange = (value) => {
    switch (value) {
      case "junior":
        $(this.minExperienceTarget).val(0);
        $(this.maxExperienceTarget).val(2);
        break;
      case "junior_or_middle":
        $(this.minExperienceTarget).val(0);
        $(this.maxExperienceTarget).val(5);
        break;
      case "middle":
        $(this.minExperienceTarget).val(2);
        $(this.maxExperienceTarget).val(5);
        break;
      case "middle_or_senior":
        $(this.minExperienceTarget).val(2);
        $(this.maxExperienceTarget).val(null);
        break;
      case "senior":
      case "senior_or_lead":
      case "lead":
        $(this.minExperienceTarget).val(5);
        $(this.maxExperienceTarget).val(null);
        break;
      case "any":
        $(this.minExperienceTarget).val(0);
        $(this.maxExperienceTarget).val(null);
        break;
      default:
        $(this.minExperienceTarget).val(2);
        $(this.maxExperienceTarget).val(5);
    }
  };
}
