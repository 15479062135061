import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["linkToCopy"];

  // Method used fetchResponse to receive from the server short_link in the plain format
  // and put it inside the copy click.
  copyLink(event) {
    if (!event.detail.success) return;

    event.detail.fetchResponse.responseText.then(
      (shortLink) => {
        $(this.linkToCopyTarget).attr("data-bs-title", "Copied to clipboard");
        $(this.linkToCopyTarget).attr("data-bs-toggle", "tooltip");
        navigator.clipboard.writeText(shortLink).then(() => {
          $(this.linkToCopyTarget).tooltip("show");
          setTimeout(() => {
            $(this.linkToCopyTarget).tooltip("hide");
          }, 1000);
        });
      },
    );
  }
}
