import { Turbo } from "@hotwired/turbo-rails";
import { Modal } from "bootstrap";
import { customModal } from "../lib/custom_modal";

/**
 * A function that handles the display of a modal and the response from a button in it.
 * @param {string} text - modal window header title text. Provided by `data-confirm=text`
 * @param {HTMLFormElement} form - the form to be sent in case of confirmation
 * @param {HTMLButtonElement|undefined} submitter - element that triggered the modal window
 * @returns {Promise<boolean>}
 */
export function handleModalResponse(text, form, submitter) {
  let originalElement = submitter;

  // Natively works only when using a button in a form
  // https://github.com/hotwired/turbo/issues/811
  // This block makes it possible to use turbo_method with the link button
  if (!submitter) {
    const method = form.getAttribute("method");
    const matchingElements = document.querySelectorAll(
      `a[data-turbo-confirm="${text}"][data-turbo-method="${method}"]`,
    );

    originalElement = [...matchingElements].find((el) =>
      form.action.includes(el.href.split("?")[0])
    );
  }

  const params = { headerText: text, data: originalElement.dataset };
  const modalElement = customModal.confirm(params);
  const bootstrapModal = new Modal(modalElement, { backdrop: "static" });

  bootstrapModal.show();

  return new Promise((resolve) => {
    modalElement.querySelector("#confirm").addEventListener("click", () => {
      resolve(true);
      bootstrapModal.hide();
    });

    modalElement
      .querySelectorAll('[data-bs-dismiss="modal"]')
      .forEach((dismiss) =>
        dismiss.addEventListener(
          "click",
          () => {
            resolve(false);
            bootstrapModal.hide();
          },
        )
      );

    modalElement.addEventListener("hidden.bs.modal", () => {
      bootstrapModal.dispose();
      modalElement.remove();
    });
  });
}

export default function init() {
  Turbo.setConfirmMethod(handleModalResponse);
}
